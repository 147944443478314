import { cn } from '@/lib/utils'
import { Tooltip, TooltipTrigger, TooltipContent } from './tooltip'
import { ReactNode } from 'react'

interface SimpleTooltipProps {
    children: JSX.Element
    content: ReactNode,
    delayDuration?: number
    alignOffset?: number
    className?: string
    classNameTrigger?: string
    sideOffset?: number
}

const SimpleTooltip = ({ classNameTrigger, children, content, sideOffset = 4, delayDuration = 1, alignOffset, className, ...props }: SimpleTooltipProps) => {
    if (!content) return children;
    return (
        <Tooltip {...props} >
            <TooltipTrigger type='button' className={cn(classNameTrigger)}>{children}</TooltipTrigger>
            <TooltipContent className={cn(className)} sideOffset={sideOffset} alignOffset={alignOffset}>{content}</TooltipContent>
        </Tooltip>
    )
}

export default SimpleTooltip
