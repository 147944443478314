import { Avatar } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'

export function UserAvatar({
    user,
    className,
    style
}: { user: { name: string, photo?: string, lastname: string }, className?: string, style?: any }) {
    const { photo = '', name = '', lastname = '' } = typeof user == 'object' ? user || {} : {};
    const initials = `${name[0] || ''}${lastname[0] || ''}`.toUpperCase()

    return (
        <Avatar
            className={cn(`bg-slate-200 bg-primary duration-100 text-white w-8 h-8 flex items-center justify-center shrink-0 text-[10px] font-semibold`, className)}
            style={style || {}}
        >
            {
                photo
                    ? (
                        <img
                            src={photo}
                            className="w-full rounded-full h-full object-cover"
                            alt="User profile"
                        />
                    ) : initials
            }
        </Avatar>
    )
}