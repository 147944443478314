import { fetcher } from "@/helpers/fetcher"

export const getDocumentUrl = ({ name, mimeType = '' }) => {
    return fetcher<{ url: string}>({
        url: '/aws/documents',
        method: 'POST',
        defaultContentType: true,
        body: {
            name,
            mimeType
        }
    })
}