import { cn } from '@/lib/utils';
import { forwardRef, HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

if (typeof window !== 'undefined' && !('IntersectionObserver' in window)) {
    import('intersection-observer');
}

interface InfiniteScrollProps extends HTMLAttributes<HTMLDivElement> {
    hasMore?: boolean;
    readonly children: ReactNode;
    next?: null | (() => void);
    loader?: ReactNode;
    dataLength: number;
    className?: string;
    threshold?: number,
    classNameIntersection?: string,
    disabled?: boolean,
    loading?: boolean
}

const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(({
    hasMore = true,
    next,
    children,
    loader,
    dataLength = 0,
    className = '',
    threshold = 1.0,
    classNameIntersection,
    disabled,
    loading,
    ...props
}, ref) => {
    const [lastDataLength, setLastDataLength] = useState(dataLength);
    const [isLoading, setIsLoading] = useState<boolean>(!!loading);
    const observer = useRef<IntersectionObserver | null>(null);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const disableInfiniteScroll = !hasMore || disabled || isLoading;

    useEffect(()=>{
        if(typeof loading !== 'boolean') return;
        setIsLoading(loading)
    },[loading]);

    const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !disableInfiniteScroll) {
            setIsLoading(true);
            next && next();
        }
    }, [isLoading, hasMore, next, disableInfiniteScroll]);

    useEffect(() => {
        if (dataLength > lastDataLength) {
            setIsLoading(false);
            setLastDataLength(dataLength);
        }
    }, [dataLength, lastDataLength]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(handleObserver, { threshold });
        if (loadMoreRef.current) observer.current.observe(loadMoreRef.current);
        return () => observer.current?.disconnect();
    }, [handleObserver]);

    return (
        <div className={cn('flex flex-col overflow-y-auto', className)} ref={ref} {...props}>
            {children}
            {(isLoading && loader) && loader}
            <div ref={loadMoreRef} className={cn('h-[2px]', classNameIntersection)}></div>
        </div>
    );
});

export default InfiniteScroll;
